import { CustomFlowbiteTheme } from 'flowbite-react';

export const CUSTOM_FLOWBITE_THEME: CustomFlowbiteTheme = {
  tabs: {
    base: 'flex flex-col items-center',
    tablist: {
      styles: {
        underline: 'gap-3 justify-center flex-wrap md:flex-nowrap border-b border-t2-neutral-grey-03 whitespace-nowrap',
      },
      tabitem: {
        styles: {
          underline: {
            base: 'rounded-t-lg text-[12px] md:text-[16px] py-2 focus:ring-0',
            active: {
              on: 'active rounded-t-lg border-b border-t2-primary-brandColor text-t2-primary-brandColor',
              off: 'border-b-2 border-transparent text-t2-neutral-grey-06 hover:text-t2-primary-brandColor',
            },
          },
        },
      },
    },
  },
  textInput: {
    field: {
      icon: {
        svg: 'w-4 h-4',
      },
      input: {
        colors: {
          't2-search':
            '!py-3 rounded-[12px] !text-base text-t2-neutral-grey09 font-normal placeholder:text-t2-neutral-grey05 border-t2-neutral-grey03 focus:border-t2-neutral-grey03 focus:ring-offset-1 focus:ring-t2-primary-brandColor',
        },
        withIcon: {
          on: 'pl-8',
        },
      },
    },
  },
  button: {
    inner: {
      base: 'flex',
    },
    size: {
      sm: 'text-sm',
    },
  },
  textarea: {
    base: 'focus:!ring-0 focus:!outline focus:!outline-1 focus:!-outline-offset-1',
    colors: {
      't2-textarea':
        'focus:!ring-0 border border-gray-100 bg-white-900 rounded-[8px] focus:outline focus:outline-1 focus:-outline-offset-1 focus:ring-t2-neutral-grey-05',
    },
  },
};
