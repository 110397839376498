import { LensConfig } from '@lens-protocol/react-web';
import { bindings as wagmiBindings } from '@lens-protocol/wagmi';
import { getLensReactEnvironment } from '../utils/getLensReactEnvironment';
import { LensCookieStorageProvider } from '../storage/LensCookieStorageProvider';
import { LensDummyStorageProvider } from '../storage/LensDummyStorageProvider';
import { wagmiConfig } from '../../wagmi/wagmiConfig';

const lensProviderConfigFactory = ({ storage }: Partial<LensConfig> = {}): LensConfig => ({
  bindings: wagmiBindings(wagmiConfig),
  environment: getLensReactEnvironment(),
  storage: storage || new LensCookieStorageProvider(),
});

export const lensProviderClientSideConfig: LensConfig = lensProviderConfigFactory();

export const lensProviderServerSideConfig: LensConfig = lensProviderConfigFactory({
  storage: new LensDummyStorageProvider(),
});
