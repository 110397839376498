'use client';

import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Banner } from './Banner';
import { TBannerContextProps, TBannerContextProviderProps, EBannerType, TShowBannerHandler } from './types';

const BannerContext = createContext<TBannerContextProps>({
  showBanner: () => undefined,
  closeBanner: () => undefined,
  type: null,
});

export const BannerContextProvider = ({ children, content: initialContent }: TBannerContextProviderProps) => {
  const [type, setType] = useState<EBannerType | null>(null);
  const [content, setContent] = useState<ReactNode>(initialContent || null);
  const [closeHandler, setCloseHandler] = useState<(() => () => void) | undefined>(undefined);

  const closeBanner = useCallback(() => {
    setContent(null);
    setCloseHandler(undefined);
  }, [closeHandler]);

  const showBanner: TShowBannerHandler = ({ type, content, onClose }) => {
    if (onClose) {
      setCloseHandler(() => onClose);
    }

    setContent(content);
    setType(type);
  };

  const value = useMemo(() => ({ showBanner, closeBanner, type }), [showBanner, closeBanner, type]);

  return (
    <BannerContext.Provider value={value}>
      <Banner onClose={closeHandler}>{content}</Banner>
      {children}
    </BannerContext.Provider>
  );
};

export const useBannerContext = () => useContext(BannerContext);
