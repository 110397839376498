import { gql } from '@apollo/client';
import { userFieldsFragment } from '../fragments/userFieldsFragment';

export const getUserQuery = gql`
  ${userFieldsFragment}
  query getUserById($privyId: String!) {
    user(where: { privyId: $privyId }) {
      __typename
      ...UserFieldsFragment
    }
  }
`;
