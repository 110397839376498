import React, { FC } from 'react';

type TModalBannerVideoProps = {
  webmSrc: string;
  mp4Src: string;
};

const ModalBannerVideo: FC<TModalBannerVideoProps> = ({ webmSrc, mp4Src }) => (
  <video controls={false} autoPlay muted loop width={456}>
    <source src={webmSrc} type="video/webm" />
    <source src={mp4Src} type="video/mp4" />
  </video>
);
export default ModalBannerVideo;
