import { from, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import { apolloErrorLink } from './apolloErrorLink';

const hasNestedFile = (root: object) => {
  let hasFile = false;
  const queue = [root];
  while (queue.length > 0) {
    const current = queue.shift();
    if (!current || typeof current !== 'object') {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (current instanceof File) {
      hasFile = true;
      break;
    }
    Object.values(current).forEach((val) => queue.push(val));
  }
  return hasFile;
};

export const apolloLinkChainFactory = (graphqlApiUrl: string) =>
  from([
    apolloErrorLink,
    split(
      (operation) => hasNestedFile(operation.variables),
      createUploadLink({
        uri: graphqlApiUrl,
        credentials: 'include',
      }),
      new BatchHttpLink({
        uri: graphqlApiUrl,
        batchMax: 5,
        batchInterval: 20,
        credentials: 'include',
      }),
    ),
  ]);
