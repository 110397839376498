'use client';

import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { GoogleAnalytics, event, pageView } from 'nextjs-google-analytics';
import { GA_MEASUREMENT_ID } from 'config';
import { usePathname } from 'next/navigation';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { TAnalyticsSendEventHandler } from './types';

type TAnalyticsContext = {
  sendEvent: TAnalyticsSendEventHandler;
};

const AnalyticsContext = createContext<TAnalyticsContext>({} as TAnalyticsContext);

export type TAnalyticsProviderProps = {
  children: ReactNode;
};

export const AnalyticsProvider = ({ children }: TAnalyticsProviderProps) => {
  const pathname = usePathname();
  const { data } = useAuthenticatedUser();
  const userId = data?.user?.analyticsId || undefined;

  useEffect(() => {
    pageView({ userId }, GA_MEASUREMENT_ID);
  }, [userId]);

  const sendEvent: TAnalyticsSendEventHandler = useCallback(
    (eventName, customParams = undefined) => {
      const customParamsWithPrefix = Object.keys(customParams || {}).reduce<Record<string, any>>(
        (acc, key) => ({ ...acc, [`t2_${key}`]: (customParams as any)[key] }),
        {},
      );

      event(eventName, {
        user_id: userId,
        pathname,
        ...customParamsWithPrefix,
      });
    },
    [userId, pathname],
  );

  const contextValue = useMemo(() => ({ sendEvent }), [sendEvent]);

  return (
    <AnalyticsContext.Provider value={contextValue}>
      <GoogleAnalytics trackPageViews gaMeasurementId={GA_MEASUREMENT_ID} />
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
