const ProfileIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8971 17.1539V15.5641C15.8971 14.7209 15.5622 13.9122 14.9659 13.3159C14.3696 12.7196 13.5609 12.3846 12.7177 12.3846H6.35869C5.51543 12.3846 4.70672 12.7196 4.11045 13.3159C3.51418 13.9122 3.1792 14.7209 3.1792 15.5641V17.1539"
      stroke=""
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53837 9.20517C11.2944 9.20517 12.7179 7.78166 12.7179 6.02568C12.7179 4.2697 11.2944 2.84619 9.53837 2.84619C7.78239 2.84619 6.35889 4.2697 6.35889 6.02568C6.35889 7.78166 7.78239 9.20517 9.53837 9.20517Z"
      stroke=""
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProfileIcon;
