import * as Sentry from '@sentry/nextjs';
import { onError } from '@apollo/client/link/error';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { Operation, ServerError, ServerParseError } from '@apollo/client';
import { ApolloErrorType, SentryCustomTagName } from './constants';

const enrichGraphQLError = (scope: Sentry.Scope, operation: Operation) => (graphQLError: GraphQLErrors[0]) => {
  scope.setContext(ApolloErrorType.graphQLError, {
    message: graphQLError.message,
    locations: graphQLError.locations,
    path: graphQLError.path,
    code: graphQLError.extensions?.code,
  });
  scope.setTags({
    [SentryCustomTagName.graphQLErrorType]: ApolloErrorType.graphQLError,
    // built in Apollo error codes: https://www.apollographql.com/docs/apollo-server/data/errors/#built-in-error-codes
    // custom Keystone error codes: https://keystonejs.com/docs/graphql/overview#errors
    // custom error codes: /apps/keystone/src/services/graphQLErrors/constants.ts
    [SentryCustomTagName.graphQLErrorCode]: graphQLError.extensions?.code as number | undefined,
    [SentryCustomTagName.graphQLLocation]: graphQLError.locations?.join(', '),
    [SentryCustomTagName.graphQLPath]: graphQLError.path?.join(' > '),
    [SentryCustomTagName.graphQLOperationName]: operation.operationName,
  });
};

const enrichNetworkError = (scope: Sentry.Scope) => (networkError: NetworkError) => {
  const statusCode = (networkError as ServerError | ServerParseError | null)?.statusCode;

  scope.setContext('networkError', {
    message: networkError?.message,
    statusCode,
    error: networkError,
  });

  scope.setTags({
    [SentryCustomTagName.graphQLErrorType]: ApolloErrorType.networkError,
    [SentryCustomTagName.statusCode]: statusCode,
  });
};

export const apolloErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  Sentry.withScope((scope) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(enrichGraphQLError(scope, operation));
      return;
    }

    if (networkError) {
      enrichNetworkError(scope)(networkError);
    }
  });
});
