import { ConnectedWallet } from '@privy-io/react-auth';
import { createContext, useContext, useMemo } from 'react';
import { useConnectedWallet } from './useConnectedWallet';

type TWalletConnectionContextType = {
  connectedWallet: ConnectedWallet | undefined;
  connectWalletAsync: (() => Promise<ConnectedWallet>) | undefined;
  chainId: number | null;
  ready: boolean;
  loading: boolean;
};

const INITIAL_STATE: TWalletConnectionContextType = {
  connectedWallet: undefined,
  connectWalletAsync: undefined,
  chainId: null,
  ready: false,
  loading: false,
};

const WalletConnectionContext = createContext<TWalletConnectionContextType>(INITIAL_STATE);

export const WalletConnectionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { connectWalletAsync, connectedWallet, ready, loading, chainId } = useConnectedWallet();

  const value = useMemo(
    () => ({ connectedWallet, connectWalletAsync, ready, loading, chainId }),
    [connectedWallet, ready, loading, chainId],
  );

  return <WalletConnectionContext.Provider value={value}>{children}</WalletConnectionContext.Provider>;
};

export const useWalletConnection = () => useContext(WalletConnectionContext);
