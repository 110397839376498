/* eslint-disable class-methods-use-this */
import { SESSION_COOKIE_DOMAIN } from 'config';
import cookie from 'cookie';
import { IStorageProvider } from '../types';

export class LensCookieStorageProvider implements IStorageProvider {
  getItem(key: string) {
    const values = cookie.parse(document.cookie || '');
    return values[key] || null;
  }

  setItem(key: string, value: string) {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 1);

    const newCookie = cookie.serialize(key, value, {
      domain: SESSION_COOKIE_DOMAIN,
      secure: true,
      sameSite: 'strict',
      expires,
      path: '/',
    });
    document.cookie = newCookie;
  }

  removeItem(key: string) {
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT ;domain=${SESSION_COOKIE_DOMAIN};path=/`;
    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT ;`;
  }
}
