import { NextSeoProps } from 'next-seo';
import { PROD_APP_URL } from '../config';
import { getFeatureFlag } from '../src/components/FeatureFlags/getFeatureFlag';

const siteName = 't2.world';
const siteUrl = `https://${PROD_APP_URL}`;

export const seoInitialValues = (path: string = '') =>
  ({
    dangerouslySetAllPagesToNoIndex: getFeatureFlag('seoAllPagesNoIndexNoFollow'),
    dangerouslySetAllPagesToNoFollow: getFeatureFlag('seoAllPagesNoIndexNoFollow'),
    titleTemplate: `%s | ${siteName}`,
    canonical: `${siteUrl}${path}`,
    robotsProps: {
      maxImagePreview: 'large',
    },
    openGraph: {
      type: 'website',
      locale: 'en-gb',
      site_name: `${siteName}`,
      images: [{ url: `${siteUrl}/images/black-hole-anim-small.gif`, alt: `${siteName} logo` }],
    },
    additionalLinkTags: [{ rel: 'icon', href: '/icons/favicon.ico', type: 'image/ico' }],
  } as NextSeoProps);
