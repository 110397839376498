import isEmpty from 'lodash/isEmpty';
import { TSortedArticlesReturn } from '../../../components/Article/types';

export const skipLimitPaginationForSortedArticles = (keyArgs?: string[]) => ({
  keyArgs: keyArgs || (false as false),
  merge(existing: TSortedArticlesReturn, incoming: TSortedArticlesReturn, { args }: any) {
    const merged = !isEmpty(existing?.articles)
      ? {
          articles: existing?.articles?.slice?.(0),
          articlesCount: incoming?.articlesCount,
        }
      : {
          articles: [],
          articlesCount: incoming?.articlesCount,
        };

    if (!args) {
      throw new Error(`No args provided to paginated field, keyArgs: ${keyArgs?.join(', ')}`);
    }

    const { skip = 0 } = args;

    if (!skip) {
      return incoming;
    }

    for (let i = 0; i < incoming.articles.length; i += 1) {
      merged.articles[skip + i] = incoming.articles[i];
    }

    return merged;
  },
});
