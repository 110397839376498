import { gql } from '@apollo/client';

export const updateUserByIdMutation = gql`
  mutation updateUserByIdMutation($userId: ID!, $data: UserUpdateInput!) {
    updateUser(where: { id: $userId }, data: $data) {
      id
      name
      cumulativeTimePoints
      shouldStoreArticlesOnArweave
      publicAddress
      twitterHandle
      username
      bio
      bioLink
      emailVerified
      profileImage {
        id
        publicUrl
        publicUrlTransformed
        _meta {
          public_id
          height
          width
        }
      }
      settingsNotificationReplyToPrompt
      settingsNotificationNewComment
      settingsNotificationReplyToComment
      settingsNotificationPostCollect
    }
  }
`;
