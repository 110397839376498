import { gql } from '@apollo/client';

export const getNewNotificationsCountQuery = gql`
  query getNewNotificationsCount($recipientId: ID!) {
    notificationsCount(
      where: {
        recipient: { id: { equals: $recipientId } }
        status: { equals: NEW }
        NOT: [
          { initiator: { deactivated: { equals: true } } }
          { relatedArticle: { deactivated: { equals: true } } }
          { relatedComment: { deactivated: { equals: true } } }
        ]
      }
    )
  }
`;
