import React from 'react';

const useSteps = <T,>(steps: readonly T[]) => {
  const [currentStepIndex, setCurrentStepIndex] = React.useState<number>(0);

  const nextStep = () => setCurrentStepIndex((prev) => prev + 1);

  const prevStep = () => setCurrentStepIndex((prev) => prev - 1);

  const reset = () => setCurrentStepIndex(0);

  return { currentStep: steps[currentStepIndex], currentStepIndex, nextStep, prevStep, reset };
};
export default useSteps;
