import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import OnboardingModal from './OnboardingModal';
import { useReactiveHasPermission } from '../Permissions/hooks/useHasPermission';

const OnboardingModalContainer = () => {
  const userRequest = useReactiveAuthenticatedUser();
  const user = userRequest?.data?.user;
  const hasPermission = useReactiveHasPermission();

  if (!user || user?.hasSeenOnboardingModal || !hasPermission('GLOBAL_CAN_ACCESS_APP')) {
    return null;
  }
  return <OnboardingModal userId={user?.id as string} />;
};
export default OnboardingModalContainer;
