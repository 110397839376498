type TArrowLeftIconProps = {
  className?: string;
};

const ArrowLeftIcon: React.FC<TArrowLeftIconProps> = ({ className }) => (
  <svg className={className} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8337 10.5H4.16699" stroke="" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.0003 16.3334L4.16699 10.5001L10.0003 4.66675"
      stroke=""
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowLeftIcon;
