import React from 'react';

const Save = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.8337 9.23355V10.0002C18.8326 11.7972 18.2507 13.5458 17.1748 14.9851C16.0988 16.4244 14.5864 17.4773 12.8631 17.9868C11.1399 18.4963 9.29804 18.4351 7.61238 17.8124C5.92673 17.1896 4.48754 16.0386 3.50946 14.5311C2.53138 13.0236 2.06682 11.2403 2.18506 9.44714C2.30329 7.65402 2.998 5.94715 4.16556 4.58111C5.33312 3.21506 6.91098 2.26303 8.66382 1.867C10.4167 1.47097 12.2506 1.65216 13.892 2.38355"
      stroke="#F9F9F9"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.8333 3.3335L10.5 11.6752L8 9.17516"
      stroke="#F9F9F9"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Save;
