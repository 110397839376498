import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { NOTIFICATIONS_COUNT_REFRESH_INTERVAL } from './constants';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { getNewNotificationsCountQuery } from '../Notification/query/getNewNotificationsCountQuery';
import { useT2Query } from '../../hooks/useT2Query';

type TNotificationBellContextProps = {
  hasNotifications: boolean;
};

const NotificationBellContext = createContext<TNotificationBellContextProps>({
  hasNotifications: false,
});

export const NotificationBellContextProvider = ({ children }: { children: ReactNode }) => {
  const { authenticatedUserId } = useAuthenticatedUser();
  const { data } = useT2Query(getNewNotificationsCountQuery, {
    variables: { recipientId: authenticatedUserId },
    skip: !authenticatedUserId,
    pollInterval: NOTIFICATIONS_COUNT_REFRESH_INTERVAL,
  });

  const hasNotifications = data?.notificationsCount > 0;

  const value = useMemo(
    () => ({
      hasNotifications,
    }),
    [hasNotifications],
  );

  return <NotificationBellContext.Provider value={value}>{children}</NotificationBellContext.Provider>;
};

export const useNotificationBellContext = () => useContext(NotificationBellContext);
