import { UAParser } from 'ua-parser-js';

if (typeof window === 'object') {
  const head = document.getElementsByTagName('HEAD')[0];
  const parser = new UAParser();
  const result = parser.getResult();

  if (result.os.name === 'Windows') {
    const style = document.createElement('style');

    style.textContent = `
    ::-webkit-scrollbar {      
      background-color: transparent;
      width: 8px;
    }
    ::-webkit-scrollbar-track {    
      background-color: #CACACA;
    }
    ::-webkit-scrollbar-thumb {      
      background-color: rgba(124, 124, 124, 0.7);
      border-radius: 16px;
    }    
    `;

    head.appendChild(style);
  }
}
