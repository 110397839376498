import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { SsrContext } from '../components/SsrContext/SsrContext';

export const useT2Query: typeof useQuery = (query, options = {}, ...rest) => {
  const ssrFromContext = useContext(SsrContext);
  const ssr = options.ssr ?? ssrFromContext;
  return useQuery(
    query,
    {
      ...options,
      ssr,
    },
    ...rest,
  );
};
