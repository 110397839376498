import { NextPage } from 'next';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ReactNotifications } from 'react-notifications-component';
import { AppProps } from 'next/app';
import { BoxHooksContextProvider } from '@decent.xyz/box-hooks';
import '../styles/globals.css';
import '../styles/index.css';
import { hotjar } from 'react-hotjar';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/compat/router';
import { PrivyProvider } from 't2-keystone/packages/privyAuthentication/src/components/PrivyProvider';
import { WagmiProvider } from '@privy-io/wagmi';
import { QueryClientProvider } from '@tanstack/react-query';
import { seoInitialValues } from '../../utils/seo';
import { getApolloFrontendClient } from '../services/apolloClient/frontend';
import { getApolloServerClient } from '../services/apolloClient/server';
import { isBrowser } from '../services/browser/isBrowser';
import '../types/global';
import { HOTJAR_CONFIG, PRIVY_APP_ID, FACEBOOK_PIXEL_ID } from '../../config';
import DiscordButtonShownOnScrollUp from '../components/DiscordButton/DiscordButtonShownOnScrollUp';
import '../../utils/detect-os';
import { BannerContextProvider } from '../components/Banner/BannerContext';
import { queryClient, wagmiConfig } from '../services/wagmi/wagmiConfig';
import { LensProviderAdapter } from '../services/lens/LensProviderAdapter/LensProviderAdapter';
import { getFeatureFlag } from '../components/FeatureFlags/getFeatureFlag';
import OnboardingModalContainer from '../components/OnboardingModal/OnboardingModalContainer';
import { AnalyticsProvider } from '../components/AnalyticsProvider/AnalyticsProvider';
import { NotificationBellContextProvider } from '../components/NavbarNotificationBell/NavbarNotificationBellContext';
import { WalletConnectionContextProvider } from '../services/walletConnection/WalletConnectionContext';
import { FlowbiteProvider } from '../services/flowbite/FlowbiteTheme';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { metadata, ...pagePropsExceptMetadata } = pageProps;
  const { asPath, events } = useRouter() || {};
  const pathWithoutQueryParams = asPath?.split('?')?.[0];

  useEffect(() => {
    import('react-facebook-pixel').then((module) => {
      const ReactPixel = module.default;
      ReactPixel.init(FACEBOOK_PIXEL_ID as string);
      ReactPixel.pageView();

      const handleRouteChangeComplete = () => {
        ReactPixel.pageView();
      };

      events?.on('routeChangeComplete', handleRouteChangeComplete);

      return () => {
        events?.off('routeChangeComplete', handleRouteChangeComplete);
      };
    });
  }, [events]);

  const apolloClient = isBrowser()
    ? getApolloFrontendClient({ initialState: metadata?.apolloClient?.cache })
    : getApolloServerClient(metadata?.apolloClient?.args);

  useEffect(() => {
    if (HOTJAR_CONFIG?.ID && HOTJAR_CONFIG?.SV) {
      hotjar.initialize(HOTJAR_CONFIG.ID as unknown as number, HOTJAR_CONFIG.SV as unknown as number);
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <ApolloProvider client={apolloClient}>
      <PrivyProvider appId={PRIVY_APP_ID as string}>
        <QueryClientProvider client={queryClient}>
          <WagmiProvider config={wagmiConfig}>
            <LensProviderAdapter>
              <WalletConnectionContextProvider>
                <BoxHooksContextProvider apiKey={process.env.DECENT_API_KEY as string}>
                  <AnalyticsProvider>
                    <FlowbiteProvider>
                      <ReactNotifications />
                      <BannerContextProvider>
                        <DefaultSeo {...seoInitialValues(pathWithoutQueryParams)} />
                        <NotificationBellContextProvider>
                          {Component.getLayout ? (
                            getLayout(<Component {...pagePropsExceptMetadata} />)
                          ) : (
                            <Component {...pagePropsExceptMetadata} />
                          )}
                        </NotificationBellContextProvider>
                      </BannerContextProvider>
                      {getFeatureFlag('onboardingModal') && <OnboardingModalContainer />}
                    </FlowbiteProvider>
                  </AnalyticsProvider>
                </BoxHooksContextProvider>
              </WalletConnectionContextProvider>
            </LensProviderAdapter>
          </WagmiProvider>
        </QueryClientProvider>
        <DiscordButtonShownOnScrollUp />
      </PrivyProvider>
    </ApolloProvider>
  );
};

export default MyApp;
