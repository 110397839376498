import React, { FC } from 'react';
import Typo from '../../Typography';
import Icon from '../../Icon';
import ModalBannerVideo from './ModalBannerVideo';

type TWelcomeToT2ModalContentProps = {
  goToNextStep: () => void;
};

const WelcomeToT2ModalContent: FC<TWelcomeToT2ModalContentProps> = ({ goToNextStep }) => (
  <div className="flex flex-col items-center">
    <Typo variant="p1" className="!font-semibold mb-8 mt-6">
      Welcome to t2!
    </Typo>
    <ModalBannerVideo
      webmSrc="/images/onboarding/welcome-to-t2-onboarding-modal.webm"
      mp4Src="/images/onboarding/welcome-to-t2-onboarding-modal.mp4"
    />

    <div className="flex flex-col gap-y-4 mt-10 mb-6">
      <Typo variant="small-p" className="!font-semibold">
        What can you do on t2?
      </Typo>
      <Typo variant="small-p" tag="div">
        <ul className="ml-5 list-disc">
          <li>Read and write to be rewarded with Time Points (t.p.)</li>
          <li>Use t.p. to become members of Territories to participate in depth discussions and share your ideas</li>
          <li>Inspire and get inspired to make our time²</li>
        </ul>
      </Typo>
    </div>
    <div className="w-full">
      <button onClick={goToNextStep} className="!font-semibold text-green-600 underline flex">
        Learn about Lens features
        <span className="ml-4">
          <Icon type="ArrowRight" />
        </span>
      </button>
    </div>
  </div>
);

export default WelcomeToT2ModalContent;
