import { ApolloClient, ApolloClientOptions } from '@apollo/client';
import merge from 'lodash/merge';
import { GRAPHQL_API_URL } from '../../../config';
import { cacheFactory } from './cache';
import { apolloLinkChainFactory } from './apolloLinkChainFactory';

export type TCacheShape = any;

let apolloClient: ApolloClient<TCacheShape>;

export const getApolloFrontendClientOptions = ({
  graphqlApiUrl = GRAPHQL_API_URL,
} = {}): ApolloClientOptions<TCacheShape> => ({
  link: apolloLinkChainFactory(graphqlApiUrl),
  connectToDevTools: true,
  cache: cacheFactory(),
});

export const getApolloFrontendClient = ({ initialState = {}, graphqlApiUrl = GRAPHQL_API_URL } = {}) => {
  if (!apolloClient) {
    apolloClient = new ApolloClient(getApolloFrontendClientOptions({ graphqlApiUrl }));
  }

  if (initialState) {
    const existingCache = apolloClient.extract();
    const newCache = merge({}, existingCache, initialState);
    apolloClient.cache.restore(newCache);
  }

  return apolloClient;
};
