import React, { FC } from 'react';
import ArrowLeftIcon from '../../Icons/ArrowLeftIcon';
import Typo from '../../Typography';
import Button from '../../Button';
import ModalBannerVideo from './ModalBannerVideo';

type TCommentOnLensModalContentProps = {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
};

const CommentOnLensModalContent: FC<TCommentOnLensModalContentProps> = ({ goToNextStep, goToPreviousStep }) => (
  <div className="relative flex flex-col items-center">
    <button
      onClick={goToPreviousStep}
      className="absolute left-0 top-0 flex basis-1/3 justify-self-start gap-x-2 items-center text-gray-700 stroke-gray-700 hover:stroke-orange-500 hover:text-orange-500"
    >
      <ArrowLeftIcon className="w-5 h-5" /> Back
    </button>
    <Typo variant="p1" className="!font-semibold mb-8 mt-6">
      t2 x Lens!
    </Typo>

    <ModalBannerVideo
      webmSrc="/images/onboarding/comment-on-lens-onboarding-modal.webm"
      mp4Src="/images/onboarding/comment-on-lens-onboarding-modal.mp4"
    />

    <div className="flex flex-col gap-y-4 mt-10 mb-6">
      <Typo variant="small-p" className="!font-semibold">
        Comment with Lens
      </Typo>
      <Typo variant="small-p" tag="div">
        Comments and likes on amplified posts will display on both t2 and Lens platforms, allowing seamless discussions
        with readers across any Lens-compatible app.
      </Typo>
    </div>
    <div className="w-full flex justify-end">
      <Button onClick={goToNextStep} variant="primary" className="!px-4" rounded showRightArrow>
        Next
      </Button>
    </div>
  </div>
);

export default CommentOnLensModalContent;
