export const GAIN_EARLY_ACCESS_URL = 'https://tpvk67rvwpq.typeform.com/to/d8wrzPbo';

export const MANIFESTO_APP_URL = 'https://manifesto.t2.world/';
export const DISCORD_URL = 'https://discord.gg/XSr8fAjQp8';
export const GITBOOK_URL = 'https://t2-world.gitbook.io/docs';

export const NEXT_PUBLIC_APP_URL =
  process.env.NEXT_PUBLIC_APP_URL ||
  (process.env.RAILWAY_SERVICE_APP_URL && `https://${process.env.RAILWAY_SERVICE_APP_URL}`) ||
  'http://localhost:4000';

export const FOOTER_URLS = {
  HOME: NEXT_PUBLIC_APP_URL,
  TWITTER: 'https://twitter.com/t2wrld',
  DISCORD: DISCORD_URL,
  HEY: 'https://hey.xyz/u/t2world',
  MANIFESTO: MANIFESTO_APP_URL,
  CAREER: 'https://angel.co/company/t2-world/jobs',
  TERMS_OF_SERVICE: 'https://t2world.notion.site/t2-world-Terms-of-Service-3b251c65386b40349b5930337f2a714c',
  PRIVACY_NOTICE: 'https://t2world.notion.site/t2-world-Privacy-Policy-5973f6c615a946b7ac8b543f2e03a2e7',
  DOCS: GITBOOK_URL,
};

export const LEARN_MORE_URL = 'https://app.t2.world/article/closqogbd76451xmcw0dzfyh9';
export const GUILD_T2_PROFILE_URL = 'https://guild.xyz/t2world';

// TODO: Get URL from the product team
export const T2_CONTENT_STORAGE_GUIDE_URL = 'https://t2.world';
export const EXTERNAL_LINK_REL_ATTRIBUTE_VALUE = 'noreferrer nofollow ugc';
