import React from 'react';

type TArrowRightProps = {
  height?: string;
  width?: string;
  className?: string;
};

const ArrowRight = ({ height = '24', width = '24', className }: TArrowRightProps) => (
  <svg
    width={height}
    height={width}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 5L19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ArrowRight;
