import React, { FC } from 'react';
import Typo from '../Typography/Typo';

type TModalHeaderProps = {
  title?: string;
  subtitle?: string;
};

const ModalHeader: FC<TModalHeaderProps> = ({ title, subtitle }) => (
  <div className="relative flex px-10 mt-5 mb-6 flex-col gap-y-2 items-center justify-center w-full text-center">
    <Typo variant="p2" className="!text-[22px] !leading-8 font-semibold">
      {title}
    </Typo>
    {subtitle && (
      <Typo variant="p2" className="!text-sm">
        {subtitle}
      </Typo>
    )}
  </div>
);

export default ModalHeader;
