import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, TModalProps } from './Modal';
import { isBrowser } from '../../services/browser/isBrowser';
import { disableGlobalScrollbar } from './utils/disableGlobalScrollbar';
import { enableGlobalScrollbar } from './utils/enableGlobalScrollbar';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { AnalyticsModalType } from './constants';

type TModalAnalyticsParams = {
  modalName: AnalyticsModalType;
};

type TUseModalProps = {
  initialState?: boolean;
  isCloseEnabled?: boolean;
  closeModalCallback?: () => void;
  analytics?: TModalAnalyticsParams;
};

export type TGetModalComponent = (
  props: Pick<
    TModalProps,
    'children' | 'title' | 'subtitle' | 'className' | 'containerClassName' | 'footer' | 'closeButtonProps'
  >,
) => JSX.Element;

export const useModal = ({
  initialState = false,
  isCloseEnabled = true,
  closeModalCallback,
  analytics,
}: TUseModalProps = {}) => {
  const { sendEvent } = useAnalytics();
  const [isOpen, setIsOpen] = useState(initialState);
  const { modalName } = analytics || {};

  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (isBrowser()) {
      enableGlobalScrollbar();
    }
    if (closeModalCallback) {
      closeModalCallback();
    }

    if (modalName) {
      sendEvent('modal_close', { modal_name: modalName });
    }
  }, [modalName]);

  const openModal = useCallback(() => {
    if (modalName) {
      sendEvent('modal_open', { modal_name: modalName });
    }

    setIsOpen(true);
    if (isBrowser()) {
      disableGlobalScrollbar();
    }
  }, [modalName]);

  const handleEscapeKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        closeModal();
      }
    },
    [closeModal, isOpen],
  );

  useEffect(() => {
    if (isCloseEnabled) {
      window.addEventListener('keyup', handleEscapeKeyUp);
    }

    return () => {
      window.removeEventListener('keyup', handleEscapeKeyUp);
    };
  }, [handleEscapeKeyUp, isCloseEnabled]);

  const getModalComponent: TGetModalComponent = useMemo(
    () =>
      (
        props: Pick<
          TModalProps,
          'children' | 'title' | 'subtitle' | 'className' | 'containerClassName' | 'footer' | 'closeButtonProps'
        >,
      ) =>
        <Modal onClose={isCloseEnabled ? closeModal : undefined} isOpen={isOpen} {...props} />,
    [closeModal, isOpen, isCloseEnabled],
  );

  return { isOpen, closeModal, openModal, ModalComponent: getModalComponent };
};
