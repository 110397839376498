import { gql } from '@apollo/client';

export const userFieldsFragment = gql`
  fragment UserFieldsFragment on User {
    id
    analyticsId
    privyId
    publicAddress
    hasSeenOnboardingModal
    username
    name
    email
    isConnectedWithLens
    cumulativeTimePoints
    memberships(where: { status: { equals: ACTIVE } }) {
      id
      status
      role {
        id
        scope
        territory {
          id
        }
        permissions {
          id
          name
          scope
        }
      }
    }
    profileImage {
      publicUrl
      publicUrlTransformed
      id
      _meta {
        public_id
        height
        width
      }
    }
    shouldUploadArticleOnLens
    shouldUploadCommentOnLens
    deactivated
    emailVerified
  }
`;
