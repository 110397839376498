'use client';

import { createPortal } from 'react-dom';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import { NAVBAR_ID } from '../Navbar/constants';
import { CloseButton } from '../CloseButton/CloseButton';
import { useBannerContext } from './BannerContext';

type TBannerProps = {
  children: ReactNode;
  onClose?: () => void;
};

export const Banner = ({ children, onClose }: TBannerProps) => {
  const navbarRef = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);
  const pathname = usePathname();
  const [, forceUpdate] = useState(0);
  const { closeBanner } = useBannerContext();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      const navbar = document.getElementById(NAVBAR_ID);
      navbarRef.current = navbar;
      forceUpdate((prev) => prev + 1);
    }
  }, [mounted, pathname]);

  const onCloseButtonClick = () => {
    closeBanner();
    onClose?.();
  };

  if (!navbarRef.current || !mounted || !children) {
    return null;
  }

  return createPortal(
    <div className="p-1 w-full bg-beige-100 text-center flex items-center">
      <div className="grow mr-5">{children}</div>
      <div className="absolute flex right-2">
        <CloseButton onClick={onCloseButtonClick} />
      </div>
    </div>,
    navbarRef.current,
  );
};
