import get from 'lodash/get';
import memoizee from 'memoizee';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { ApolloClient, ApolloClientOptions, NormalizedCacheObject, from } from '@apollo/client';
import { parse, serialize } from 'cookie';
import { APOLLO_SERVER_CLIENT_MAX_AGE, GRAPHQL_API_URL } from '../../../config';
import { cacheFactory } from './cache';
import { PRIVY_AUTH_COOKIE_NAME } from '../../components/Auth/constants';
import { apolloErrorLink } from './apolloErrorLink';

export const getAuthCookieName = () => PRIVY_AUTH_COOKIE_NAME;
export const getAuthCookie = (cookie: string = ''): string => get(parse(cookie), getAuthCookieName(), '');

export const getCookiesForApolloServerClient = (cookie: string = ''): string =>
  serialize(getAuthCookieName(), getAuthCookie(cookie));

export const getApolloServerClientOptions = ({
  cookie = undefined,
  graphqlApiUrl = GRAPHQL_API_URL,
} = {}): ApolloClientOptions<NormalizedCacheObject> => ({
  ssrMode: true,
  link: from([
    apolloErrorLink,
    new BatchHttpLink({
      uri: graphqlApiUrl,
      credentials: 'same-origin',
      headers: {
        // @ts-ignore
        cookie,
      },
      batchMax: 5,
      batchInterval: 20,
    }),
  ]),
  cache: cacheFactory(),
});

export const getApolloServerClient = memoizee((...args) => new ApolloClient(getApolloServerClientOptions(...args)), {
  normalizer: (args) => args[0]?.cookie || '',
  maxAge: APOLLO_SERVER_CLIENT_MAX_AGE,
});
