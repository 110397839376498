'use client';

import isFunction from 'lodash/isFunction';
import find from 'lodash/find';
import { TPermissions } from 't2-keystone/src/schema/Permission/permissions';
import { useAuthenticatedUser, useReactiveAuthenticatedUser } from '../../Auth/hooks/useAuthenticatedUser';
import { getAllPermissionsFromUserRequest } from '../services/getAllPermissionsFromAuthenticatedItemRequest';

export type THasPermission = (
  permissionArg: TPermissions | ((permissions: TPermissions[]) => boolean),
  permissionArg2?: string,
) => boolean;

export const useHasPermission = () => {
  const userRequest = useAuthenticatedUser();
  return hasPermissionFactory(userRequest);
};

export const useReactiveHasPermission = () => {
  const userRequest = useReactiveAuthenticatedUser();
  return hasPermissionFactory(userRequest);
};

export const hasPermissionFactory = (userRequest): THasPermission => {
  const userPermissions: TPermissions[] = getAllPermissionsFromUserRequest(userRequest);

  return (permissionArg, permissionArg2) => {
    if (isFunction(permissionArg)) {
      return permissionArg(userPermissions);
    }
    return !!find(
      userPermissions,
      permissionArg2 ? { name: permissionArg, territoryId: permissionArg2 } : { name: permissionArg },
    );
  };
};
