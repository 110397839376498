import React, { FC } from 'react';
import { useRouter } from 'next/compat/router';
import ArrowLeftIcon from '../../Icons/ArrowLeftIcon';
import Typo from '../../Typography';
import Button from '../../Button';
import ModalBannerVideo from './ModalBannerVideo';
import { getSettingsPageUrl } from '../../../services/pagesUrl/getSettingsPageUrl';
import { SettingsSubpage } from '../../UserSettings/constants';

type TUpdateProfileModalContentProps = {
  goToPreviousStep: () => void;
  closeModal: () => void;
};

const UpdateProfileModalContent: FC<TUpdateProfileModalContentProps> = ({ goToPreviousStep, closeModal }) => {
  const router = useRouter();
  const handleUpdateProfile = () => {
    router?.push(getSettingsPageUrl(SettingsSubpage.lens));
    closeModal();
  };

  return (
    <div className="relative flex flex-col items-center">
      <button
        onClick={goToPreviousStep}
        className="absolute left-0 top-0 flex basis-1/3 justify-self-start gap-x-2 items-center text-gray-700 stroke-gray-700 hover:stroke-orange-500 hover:text-orange-500"
      >
        <ArrowLeftIcon className="w-5 h-5" /> Back
      </button>
      <Typo variant="p1" className="!font-semibold mb-8 mt-6">
        t2 x Lens!
      </Typo>

      <ModalBannerVideo
        webmSrc="/images/onboarding/update-profile-with-lens-onboarding-modal.webm"
        mp4Src="/images/onboarding/update-profile-with-lens-onboarding-modal.mp4"
      />

      <div className="flex flex-col gap-y-4 mt-10 mb-6">
        <Typo variant="small-p" className="!font-semibold">
          Update profile with Lens
        </Typo>
        <Typo variant="small-p" tag="div">
          Update your t2 profile with your latest Lens profile details by importing your display name, profile image,
          bio, and links.
        </Typo>
      </div>
      <div className="flex justify-center items-center gap-x-2 w-full">
        <Button variant="primary" className="!w-[135px] flex justify-center" rounded outlined onClick={closeModal}>
          Got it!
        </Button>
        <Button onClick={handleUpdateProfile} variant="primary" className="!w-[135px] flex justify-center" rounded>
          Update profile
        </Button>
      </div>
    </div>
  );
};

export default UpdateProfileModalContent;
