/* eslint-disable class-methods-use-this */
import { IStorageProvider } from '@lens-protocol/storage';

export class LensDummyStorageProvider implements IStorageProvider {
  getItem(key: string) {
    // eslint-disable-next-line no-console
    console.log('LensDummyStorageProvider.getItem', key);
    return null;
  }

  setItem(key: string) {
    // eslint-disable-next-line no-console
    console.log('LensDummyStorageProvider.setItem', key);
  }

  removeItem(key: string) {
    // eslint-disable-next-line no-console
    console.log('LensDummyStorageProvider.removeItem', key);
  }
}
