export const KEYSTONE_AUTH_COOKIE_NAME = 'keystonejs-session';

export const enum ApolloErrorType {
  graphQLError = 'graphQLError',
  networkError = 'networkError',
}

export const enum SentryCustomTagName {
  statusCode = 'statusCode',
  graphQLErrorType = 'graphQLErrorType',
  graphQLErrorCode = 'graphQLErrorCode',
  graphQLPath = 'graphQLPath',
  graphQLLocation = 'graphQLLocation',
  graphQLOperationName = 'graphQLOperationName',
}
