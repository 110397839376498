import cx from 'classnames';
import { CrossIcon } from '../Icons/CrossIcon';

type TCloseModalButtonProps = {
  onClick: () => void;
  description?: string;
  className?: string;
};

export const CloseButton = ({ onClick, description = 'Close modal', className }: TCloseModalButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      'text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm inline-flex items-center',
      className,
    )}
  >
    <CrossIcon />
    <span className="sr-only">{description}</span>
  </button>
);
