import { PrivyInterface, usePrivy as originalUsePrivy, useLogin, PrivyEvents } from '@privy-io/react-auth';
import { useRef } from 'react';
import { TPrivyLoginResult } from '../types';

type TUsePrivyResult = Omit<PrivyInterface, 'login'> & {
  login: () => Promise<TPrivyLoginResult>;
};

type TUsePrivyProps = {
  loginOptions?: PrivyEvents['login'];
};

const useCreatePromise = <T, S>() => {
  const resolvedData = useRef<T>();
  const rejectedData = useRef<S>();
  const resolver = useRef<(value: T | PromiseLike<T>) => void>();
  const rejector = useRef<(error: S) => void>();

  const setResolvedData = (data: T) => {
    if (resolver.current) {
      resolver.current(data);
    }
    resolvedData.current = data;
  };

  const setRejectedData = (data: S) => {
    if (rejector.current) {
      rejector.current(data);
    }
    rejectedData.current = data;
  };

  const getPromise = () =>
    new Promise((resolve, reject) => {
      if (resolvedData.current) {
        resolve(resolvedData.current);
      } else if (rejectedData.current) {
        reject(rejectedData.current);
      } else {
        resolver.current = resolve;
        rejector.current = reject;
      }
    });

  return { setResolvedData, setRejectedData, getPromise };
};

export const usePrivy = ({ loginOptions }: TUsePrivyProps = {}): TUsePrivyResult => {
  const { setResolvedData, setRejectedData, getPromise } = useCreatePromise<TPrivyLoginResult, any>();
  const originalUsePrivyResult = originalUsePrivy();
  const { login } = useLogin({
    onError: (error) => {
      loginOptions?.onError?.(error);
      setRejectedData(error);
    },
    onComplete: (user, isNewUser, wasAlreadyAuthenticated, method) => {
      loginOptions?.onComplete?.(user, isNewUser, wasAlreadyAuthenticated, method);
      setResolvedData({ ...user, isNewUser });
    },
  });

  return {
    ...originalUsePrivyResult,
    login: async () => {
      login();
      return await getPromise();
    },
  };
};
