const PublishingIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.17944 15.9613C3.17944 15.4343 3.38881 14.9288 3.76147 14.5562C4.13414 14.1835 4.63959 13.9741 5.16662 13.9741H15.8974"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.16662 2.05127H15.8974V17.9487H5.16662C4.63959 17.9487 4.13414 17.7393 3.76147 17.3667C3.38881 16.994 3.17944 16.4886 3.17944 15.9615V4.03845C3.17944 3.51142 3.38881 3.00597 3.76147 2.6333C4.13414 2.26063 4.63959 2.05127 5.16662 2.05127V2.05127Z"
      strokeWidth="1.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PublishingIcon;
