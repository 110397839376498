import { FC } from 'react';
import { useMutation } from '@apollo/client';
import { User as TUser } from 't2-graphql-types';
import { useRouter } from 'next/compat/router';
import { useModal } from '../Modal/useModal';
import { updateUserByIdMutation } from '../UserSettings/mutations/updateUserById';
import { TUpdateUserInputData } from '../UserSettings/hooks/useSettingsProfileContainer';
import OnboardingModalContentContainer from './ModalContent/OnboardingModalContentContainer';

type TOnboardingModalProps = {
  userId: string;
};

const OnboardingModal: FC<TOnboardingModalProps> = ({ userId }) => {
  const router = useRouter();
  const [updateUser] = useMutation<Partial<TUser>, TUpdateUserInputData>(updateUserByIdMutation);

  const handleModalClosed = async () => {
    await updateUser({
      variables: {
        userId,
        data: {
          hasSeenOnboardingModal: true,
        },
      },
    });

    const redirectUrl = typeof router?.query?.redirect === 'string' ? router?.query.redirect : '/';
    router?.push(redirectUrl);
  };

  const { ModalComponent, closeModal } = useModal({ initialState: true, closeModalCallback: handleModalClosed });

  return (
    <ModalComponent containerClassName="sm:!max-h-fit">
      <OnboardingModalContentContainer closeModal={closeModal} />
    </ModalComponent>
  );
};

export default OnboardingModal;
