import cx from 'classnames';
import { DISCORD_URL } from '../../../utils/constants';
import Typo from '../Typography/Typo';

export interface DiscordButtonProps {
  show: boolean;
}

const DiscordButton: React.FC<DiscordButtonProps> = ({ show }) => (
  <div className="absolute right-14 md:right-36">
    <a
      href={DISCORD_URL}
      className={cx(
        'fixed px-3 py-3 md:py-1 bottom-10 md:bottom-14 flex flex-row gap-x-3 bg-white-900 border-2 border-white-900 rounded-[50px] shadow-discordSupport z-40',
        {
          'visible animate-discord-up': show,
          'invisible animate-discord-down duration-200': !show,
        },
      )}
      target="_blank"
      rel="noreferrer nofollow"
    >
      <Typo variant="p2" text="Support" className="hidden md:block !text-p4" />
      <span className="flex items-center justify-center w-1 h-1 md:w-fit md:h-fit before:content-discord before:h-fit" />
    </a>
  </div>
);

export default DiscordButton;
