'use client';

import { LensProvider, LensProviderProps } from '@lens-protocol/react-web';
import { lensProviderClientSideConfig } from './lensProviderConfig';

export const LensProviderAdapter = ({ children, config, ...props }: Partial<LensProviderProps>) => (
  <LensProvider {...props} config={config || lensProviderClientSideConfig}>
    {children}
  </LensProvider>
);
