import { User } from 't2-graphql-types';
import { getAllPermissionsInRoles } from 't2-keystone/src/schema/Permission/services/getAllPermissionsInRoles';
import { getUserActiveRoles } from './getUserActiveRoles';

type TAuthenticatedItemResponseLike = {
  data: {
    authenticatedItem: User;
  };
};
type TUserLike = {
  data: {
    user: User;
  };
};

export const getAllPermissionsFromAuthenticatedItemRequest = (
  authenticatedItemRequest: TAuthenticatedItemResponseLike,
) => getAllPermissionsInRoles(getUserActiveRoles(authenticatedItemRequest?.data?.authenticatedItem)) || [];

export const getAllPermissionsFromUserRequest = (authenticatedItemRequest: TUserLike) =>
  getAllPermissionsInRoles(getUserActiveRoles(authenticatedItemRequest?.data?.user)) || [];
