export const NotificationsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3077 6.8205C14.3077 5.55562 13.8052 4.34255 12.9108 3.44815C12.0164 2.55374 10.8033 2.05127 9.53846 2.05127C8.27358 2.05127 7.06051 2.55374 6.1661 3.44815C5.2717 4.34255 4.76923 5.55562 4.76923 6.8205C4.76923 12.3846 2.38461 13.9743 2.38461 13.9743H16.6923C16.6923 13.9743 14.3077 12.3846 14.3077 6.8205Z"
      stroke=""
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9136 17.1538C10.7738 17.3947 10.5733 17.5947 10.3319 17.7337C10.0906 17.8727 9.81696 17.9459 9.53846 17.9459C9.25995 17.9459 8.98633 17.8727 8.745 17.7337C8.50366 17.5947 8.30308 17.3947 8.16333 17.1538"
      stroke=""
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
