import { ConnectedWallet, useWallets } from '@privy-io/react-auth';
import { useEffect, useRef, useState } from 'react';
import { usePrivy } from 't2-keystone/packages/privyAuthentication/src/hooks/usePrivy';
import { useReactiveAuthenticatedUser } from 'src/components/Auth/hooks/useAuthenticatedUser';
import { WalletConnectionError } from './constants';
import { formatCaipChainIdToNumber } from './helpers/formatCaipChainIdToNumber';

enum WalletConnectionStep {
  Started,
  Connecting,
  Failure,
  Success,
  Idle,
}

export const useConnectedWallet = () => {
  const [step, setStep] = useState<WalletConnectionStep>(WalletConnectionStep.Idle);
  const resolver = useRef<(value: ConnectedWallet) => void>(null);
  const rejector = useRef<(error?: any) => void>(null);
  const { wallets, ready } = useWallets();
  const { connectWallet, user, isModalOpen } = usePrivy();
  const { data: userData } = useReactiveAuthenticatedUser();
  const sessionAddress = userData?.user?.publicAddress;

  const userWalletAddress = user?.wallet?.address;
  const connectedWallet = userWalletAddress
    ? wallets.find((wallet) => wallet.address === userWalletAddress)
    : undefined;

  const chainId = connectedWallet
    ? formatCaipChainIdToNumber(connectedWallet?.chainId?.replace?.('eip155:', ''))
    : null;

  const connectWalletAsync = () => {
    setStep(WalletConnectionStep.Started);
    connectWallet();

    // @ts-ignore
    return new Promise<ConnectedWallet>((resolve, reject) => {
      // @ts-ignore
      resolver.current = resolve;
      // @ts-ignore
      rejector.current = reject;
    });
  };

  const resetPromise = () => {
    // @ts-ignore
    resolver.current = null;
    // @ts-ignore
    rejector.current = null;
  };

  const fulfillWalletConnection = () => {
    resetPromise();
    setStep(WalletConnectionStep.Idle);
  };

  useEffect(() => {
    if (step === WalletConnectionStep.Started && !isModalOpen) {
      connectWallet();
    }
  }, [step]);

  useEffect(() => {
    if (step === WalletConnectionStep.Started && isModalOpen) {
      setStep(WalletConnectionStep.Connecting);
    }
  }, [isModalOpen, step]);

  useEffect(() => {
    if (step === WalletConnectionStep.Connecting && !isModalOpen && !connectWallet) {
      rejector.current?.(new Error(WalletConnectionError.Canceled));
      setStep(WalletConnectionStep.Failure);
    }
  }, [isModalOpen, step]);

  useEffect(() => {
    if (
      step === WalletConnectionStep.Connecting &&
      !isModalOpen &&
      connectedWallet?.address.toLowerCase() !== sessionAddress
    ) {
      rejector.current?.(new Error(WalletConnectionError.WrongAccount));
      setStep(WalletConnectionStep.Failure);
    }
  }, [isModalOpen, step]);

  useEffect(() => {
    if (
      step === WalletConnectionStep.Connecting &&
      !isModalOpen &&
      !!connectedWallet &&
      connectedWallet?.address.toLowerCase() === sessionAddress
    ) {
      resolver.current?.(connectedWallet);
      setStep(WalletConnectionStep.Success);
    }
  }, [isModalOpen, step, connectedWallet]);

  useEffect(() => {
    if ([WalletConnectionStep.Success, WalletConnectionStep.Failure].includes(step)) {
      fulfillWalletConnection();
    }
  }, [step]);

  return { connectWalletAsync, connectedWallet, ready, loading: step === WalletConnectionStep.Connecting, chainId };
};
